import React, { useState } from "react";
import NoteHistoryDetails from "./NoteHistoryDetails";

const LeadDetails = ({
  textareaHeight,
  setTextAreaHeight,
  selectAgentLeadIdWiseData,
}) => {
  const [historyDetailModel, setHistoryDetailModel] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const handleCloseHistory = () => {
    setHistoryDetailModel(false);
  };

  return (
    <>
      <div className="lead-detail-container p-1 gap-3 d-flex mx-0">
        <div className="lead-detail-container-child remove-ml-lead-details">
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Ref. No. :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.ref_no}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Property Ref. No. :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.property_ref_no}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Name :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.name}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Mobile Number :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.mobile}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Email :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.email}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Lead Type :</span>
            <span className="lead-data-key-value-data">Agent</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Agent 1 :</span>
            <span className="lead-data-key-value-data">Binayah Support</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Created By :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.date_time}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Priority :</span>
            <span className="lead-data-key-value-data">Low</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Hot Lead :</span>
            <span className="lead-data-key-value-data">Yes</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Source :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.souece}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Sublocation :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.s_location}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Country :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.country}
            </span>
          </div>
        </div>
        <div className="lead-detail-container-child">
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Owner :</span>
            <span className="lead-data-key-value-data">Agent</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Status :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.status}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Substatus :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.s_status}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Type :</span>
            <span className="lead-data-key-value-data">Rent</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Category :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.category}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Unit No. :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.unit_no}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Price :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.price}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Bedrooms :</span>
            <span className="lead-data-key-value-data">2 Beds</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Emirates :</span>
            <span className="lead-data-key-value-data">Halfweg</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Building :</span>
            <span className="lead-data-key-value-data">Not Available</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Location :</span>
            <span className="lead-data-key-value-data">
              {selectAgentLeadIdWiseData?.location}
            </span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Property :</span>
            <span className="lead-data-key-value-data">Note..</span>
          </div>
          <div className="d-flex py-2 lead-detail-container-child-data">
            <span className="lead-data-key-value">Project & Page Name :</span>
            <span className="lead-data-key-value-data">Binayah & 1</span>
          </div>
        </div>
        <div className="note-containner-lead-list lead-detail-container-child">
          <div className="lead-detail-history-btn d-flex">
            <h6 className="border-none text-black">Note</h6>
            <button
              className=""
              onClick={() => {
                setHistoryDetailModel(true);
              }}
            >
              History
            </button>
          </div>
          <div className="mt-2 note-text-area-containner">
            <textarea
              style={{
                height: textareaHeight,
                transition: "height 0.3s ease",
              }}
              onFocus={() => {
                setTextAreaHeight("150px");
                setShowButton(true);
              }}
              placeholder="Add a note..."
            ></textarea>

            {showButton && (
              <div className="lead-note-details-btn-containner">
                <button
                  className="lead-list-cancel-btn"
                  onClick={() => {
                    setTextAreaHeight("40px");
                    setShowButton(false);
                  }}
                >
                  <img
                    src="/svg/cancel_icons.png"
                    alt="close_img"
                    className="mx-1"
                  />
                </button>
                <button
                  className="lead-list-submit-btn"
                  onClick={() => {
                    setTextAreaHeight("40px");
                    setShowButton(false);
                  }}
                >
                  <img
                    src="/svg/approve.png"
                    alt="right_img"
                    className="mx-1"
                  />
                </button>
              </div>
            )}
          </div>

          <div className="chat_containner_box">
            <div className="chat_containner_box_main">
              <div className="chat_containner_box_chield d-flex my-1">
                <div className="chat_image_containner">
                  <img
                    src="/svg/user_profile_img.png"
                    alt="user_profile_img"
                    className=""
                  />
                </div>
                <div className="chat_message_box_containner">
                  <span className="message_type">Hii</span>
                  <span className="message_time">10:00AM</span>
                </div>
              </div>
            </div>
            <div className="chat_containner_box_main_right">
              <div className="chat_containner_box_chield d-flex my-1">
                <div className="chat_message_box_containner">
                  <span className="message_type">How are you !</span>
                  <span className="message_time">10:00AM</span>
                </div>
                <div className="chat_image_containner">
                  <img
                    src="/svg/user_profile_img.png"
                    alt="user_profile_img"
                    className=""
                  />
                </div>
              </div>
            </div>
            <div className="chat_containner_box_main">
              <div className="chat_containner_box_chield d-flex my-1">
                <div className="chat_image_containner">
                  <img
                    src="/svg/user_profile_img.png"
                    alt="user_profile_img"
                    className=""
                  />
                </div>
                <div className="chat_message_box_containner">
                  <span className="message_type">Hii</span>
                  <span className="message_time">10:00AM</span>
                </div>
              </div>
            </div>
            <div className="chat_containner_box_main_right">
              <div className="chat_containner_box_chield d-flex my-1">
                <div className="chat_message_box_containner">
                  <span className="message_type">How are you !</span>
                  <span className="message_time">10:00AM</span>
                </div>
                <div className="chat_image_containner">
                  <img
                    src="/svg/user_profile_img.png"
                    alt="user_profile_img"
                    className=""
                  />
                </div>
              </div>
            </div>
            <div className="chat_containner_box_main">
              <div className="chat_containner_box_chield d-flex my-1">
                <div className="chat_image_containner">
                  <img
                    src="/svg/user_profile_img.png"
                    alt="user_profile_img"
                    className=""
                  />
                </div>
                <div className="chat_message_box_containner">
                  <span className="message_type">Hii</span>
                  <span className="message_time">10:00AM</span>
                </div>
              </div>
            </div>
            <div className="chat_containner_box_main_right">
              <div className="chat_containner_box_chield d-flex my-1">
                <div className="chat_message_box_containner">
                  <span className="message_type">How are you !</span>
                  <span className="message_time">10:00AM</span>
                </div>
                <div className="chat_image_containner">
                  <img
                    src="/svg/user_profile_img.png"
                    alt="user_profile_img"
                    className=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NoteHistoryDetails
        historyDetailModel={historyDetailModel}
        handleCloseHistory={handleCloseHistory}
      />
    </>
  );
};

export default LeadDetails;
