import React, { useEffect, useState } from "react";
import { unitModelListData } from "../../../asset/StastucJsonData/unitModelJsonData";
import { Link, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";

const UnitModelList = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  let { id } = useParams();

  const findIdWiseData = (id) => {
    const findData = unitModelListData.filter((item) => item?._id === id);
    setData(findData);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  useEffect(() => {
    findIdWiseData(id);
  }, [id]);

  const column = [
    {
      name: "sr no.",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
      width: "50px",
    },
    {
      name: "unit no.",
      selector: (row) => row?.unit_no,
      center: "true",
      wrap: true,
    },
    {
      name: "unit type",
      selector: (row) => row?.unit_type,
      center: "true",
      wrap: true,
    },
    {
      name: "unit size",
      selector: (row) => row?.unit_size,
      center: "true",
      wrap: true,
    },
    {
      name: "price per ft",
      selector: (row) => row?.price_per_ft,
      center: "true",
      wrap: true,
    },
    {
      name: "sales proce",
      selector: (row) => row?.sales_proce,
      center: "true",
      wrap: true,
    },
    {
      name: "down payment",
      selector: (row) => row?.down_payment,
      center: "true",
      wrap: true,
    },
    {
      name: "three month",
      selector: (row) => row?.three_month,
      center: "true",
      wrap: true,
    },
    {
      name: "six month",
      selector: (row) => row?.six_month,
      center: "true",
      wrap: true,
    },
    {
      name: "handover",
      selector: (row) => row?.handover,
      center: "true",
      wrap: true,
    },
    {
      name: "three year plan",
      selector: (row) => row?.three_year_plan,
      center: "true",
      wrap: true,
      width: "110px",
    },
    {
      name: "edit",
      cell: (row) => (
        <>
          <Link to={`/edit-unit-model/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px"} src="/svg/pencil.png" alt="edit-img" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
      width: "60px",
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <Link className="link-hover" to={`/add-unit-model`}>
          <button
            type="button"
            className="btn filter_button add_button rounded-0"
          >
            <img
              className="mx-2"
              src="/svg/pluse_icon_button.png"
              width="25px"
              alt="images"
            />
            Add Unit Model
          </button>
        </Link>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <div className="data-table-cotainner-project">
        <DataTable
          data={filteredData ? filteredData : data}
          customStyles={customStyles}
          columns={column}
          noHeader={false}
          highlightOnHover
          persistTableHead
          pagination
          searchable
          responsive
          noDataComponent="No data available"
        />
      </div>
    </>
  );
};

export default UnitModelList;
