import React from "react";
import ListAllListing from "./ListAllListing";
import HelmetComponent from "../../Common/HelmetComponent";

const Alllisting = () => {
  return (
    <>
      <HelmetComponent title="All-Listing" />
      <ListAllListing />
    </>
  );
};

export default Alllisting;
