import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { agentListData } from "../../../asset/StastucJsonData/LocationJsonData";
import { Link } from "react-router-dom";
import CommonStatusButton from "../../Common/WarnnigModel/CommonStatusButton";

const AgentList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [listData, setListData] = useState(agentListData || []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = listData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const deleteAgent = (item) => {
    const data = listData.filter((user) => user._id !== item._id);
    setListData(data);
  };

  useEffect(() => {
    deleteAgent(listData);
  }, [listData]);

  const handleStatusChange = (objData) => {
    console.log(objData);
  };

  const column = [
    {
      name: "sr no.",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "Agent",
      selector: (row) => row?.agent_name,
      wrap: true,
    },
    {
      name: "Propertys",
      cell: (row) => (
        <>
          <Link to={`/agents-property/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"25px"} src="/svg/house.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "Permition",
      cell: (row) => (
        <>
          <Link to={`/agents-permission/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"30px"} src="/svg/permition-key.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          <div className="cursor-pointer">
            <CommonStatusButton
              changeActive={handleStatusChange}
              role={row}
              moduleName="Agent"
            />
          </div>
        </>
      ),
      center: "true",
      // wrap: true,
      width: "130px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <Link to={`/edit-agents/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px"} src="/svg/pencil.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "Delete",
      cell: (row) => (
        <>
          <div
            className="cursor-pointer"
            onClick={() => {
              deleteAgent(row);
            }}
          >
            <img width={"25px"} src="/svg/trash_images.png" alt="images" />
          </div>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <Link to="/add-agents">
          <button
            type="button"
            className="btn filter_button add_button rounded-0"
          >
            <img
              className="mx-2"
              src="/svg/pluse_icon_button.png"
              width="20px"
              alt="images"
            />
            Add New Agent
          </button>
        </Link>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <div className="mt-2 mb-2">
        <DataTable
          data={filteredData ? filteredData : agentListData}
          noDataComponent="No data available"
          customStyles={customStyles}
          highlightOnHover
          noHeader={false}
          persistTableHead
          columns={column}
          pagination
          searchable
          responsive
        />
      </div>
    </>
  );
};

export default AgentList;
