import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinyMCEEditor = ({ field, form }) => {
  const apiKey = process.env.REACT_APP_TINY_MCE_EDITOR_API_KEY;

  return (
    <div>
      <Editor
        apiKey={apiKey}
        value={field.value || ""}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help",
        }}
        onEditorChange={(content) => {
          form.setFieldValue(field.name, content);
        }}
      />
      {form.errors[field.name] && form.touched[field.name] && (
        <div className="text-danger">{form.errors[field.name]}</div>
      )}
    </div>
  );
};

export default TinyMCEEditor;
