import React, { useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { publicFolderListData } from "../../../asset/StastucJsonData/publicFolderJsonData";
import MultipleImagesUploads from "../../Common/DragAndDropMultipleImages";

const PublicFolderList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectImage, setSelectImage] = useState(null);

  const fileRef = useRef(null);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = publicFolderListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleChangeImage = (e) => {
    setSelectImage(e.target.files[0]);
  };

  const handleSubmit = () => {
    if (selectImage) {
      const newFileData = {
        file_name: selectImage.name,
        file_size: `${(selectImage.size / 1024).toFixed(2)} KB`,
      };
      publicFolderListData.push(newFileData);
      setSelectImage(null);

      if (fileRef.current) {
        fileRef.current.value = null;
      }
    }
  };

  const column = [
    {
      name: "Sr.",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
      width: "100px",
    },
    {
      name: "File Name",
      selector: (row) => row?.file_name,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Size",
      selector: (row) => row?.file_size,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Download",
      cell: (row) => (
        <>
          <div className="cursor-pointer">
            <img
              width={"20px"}
              src="/svg/download-icon.png"
              alt="download-img"
            />
          </div>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
  ];

  return (
    <>
      <MultipleImagesUploads />
      <div className="my-3 public-folder-containner">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-2 ">
            <p className="m-0 ">Image </p>
            <input
              className="form-control"
              type="file"
              onChange={handleChangeImage}
              ref={fileRef}
            />
          </div>
        </div>

        <div
          className="mt-3 mb-5"
          onClick={() => {
            handleSubmit();
          }}
        >
          <button type="button" className="btn btn-secondary">
            Submit
          </button>
        </div>
      </div>
      <div className="my-3">
        <div className="row justify-content-end">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2 search-box-in-filter-main">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-100 search-box-in-filter-main-input "
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
      </div>
      <DataTable
        data={filteredData ? filteredData : publicFolderListData}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default PublicFolderList;
