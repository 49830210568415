import React, { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import {
  callLeadData,
  filterOptionsForDataTable,
  filterStatusOption,
} from "../../../asset/StastucJsonData/callLeadJsonData";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import CustomSelect from "../../Common/Select2Box/customSelectbox";

const CallLeadList = () => {
  const [selectCallDetailsId, setSelectCallDetailsId] =
    useState("+971529285523");
  const [getCallerIdData, setGetCallerIdData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState(0);
  const [isAudioVisible, setIsAudioVisible] = useState(false);
  const [isAudioVisibleDetails, setIsAudioVisibleDetails] = useState(false);
  const [isAudioVisibleLoaderDetails, setIsAudioVisibleLoaderDetails] =
    useState(false);
  const [loadingUserIds, setLoadingUserIds] = useState({});
  const [audioVisibleUserIds, setAudioVisibleUserIds] = useState({});

  const getCallerIdWiseData = (id) => {
    const findData = callLeadData.find(
      (callerid) => callerid?.caller_id === id
    );
    setGetCallerIdData(findData);
  };

  const handleSelectChange = (option) => {
    setSelectedValue(option);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handelAudioDetails = () => {
    setIsAudioVisibleLoaderDetails(true);
    setTimeout(() => {
      setIsAudioVisibleLoaderDetails(false);
      setIsAudioVisibleDetails(!isAudioVisibleDetails);
    }, 1500);
  };

  const handelAudio = (userId) => {
    setLoadingUserIds((prevState) => ({
      ...prevState,
      [userId]: true,
    }));

    setTimeout(() => {
      setLoadingUserIds((prevState) => ({
        ...prevState,
        [userId]: false,
      }));
      setAudioVisibleUserIds((prevState) => ({
        ...prevState,
        [userId]: true,
      }));
    }, 2000);
  };

  // Search functionality for data table....

  const filteredData = callLeadData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const handleRowClicked = (row) => {
    setSelectCallDetailsId(row.caller_id);
    setIsAudioVisible(false);
  };

  useEffect(() => {
    getCallerIdWiseData(selectCallDetailsId);
  }, [selectCallDetailsId]);

  const column = [
    // {
    //   width: "3%",
    //   cell: (row, index) => {
    //     return (
    //       <div className="radio-btn-caller">
    //         <input
    //           key={index.caller_id}
    //           name="1"
    //           type="radio"
    //           className=""
    //           checked={selectCallDetailsId === row?.caller_id}
    //           onClick={() => {
    //             setSelectCallDetailsId(row?.caller_id);
    //             setIsAudioVisible(false);
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    //   center: "true",
    //   wrap: true,
    // },
    {
      name: "Caller Id",

      selector: (row) => row?.caller_id,
      center: "true",
      wrap: true,
    },
    {
      name: "Recording",

      cell: (row) => (
        <div className="data-table-audio-show text-center">
          {/* {row?.recording ? (
            <audio
              // ref="audio_tag"
              // src="./static/music/foo.mp3"
              controls
              autoPlay
              className="details-audio"
            />
          ) : (
            " - "
          )} */}
          {row?.recording ? (
            !isAudioVisible ? (
              <div>
                {loadingUserIds[row?.caller_id] ? (
                  <p className="recording-show-details">
                    <span className="loader"></span>
                  </p>
                ) : audioVisibleUserIds[row?.caller_id] ? (
                  <audio
                    src={row?.url_recording}
                    controls
                    autoPlay
                    className="details-audio"
                  />
                ) : (
                  <p className="recording-show-details">
                    <i
                      className="fa fa-play"
                      onClick={() => handelAudio(row?.caller_id)}
                    ></i>
                  </p>
                )}
              </div>
            ) : (
              <audio
                src={row?.url_recording}
                controls
                autoPlay
                className="details-audio"
              />
            )
          ) : (
            <span className="px-2"> - </span>
          )}
        </div>
      ),
      center: "true",
      wrap: true,
      width: "250px",
    },
    {
      name: "Call Date",

      selector: (row) => row?.call_date,
      center: "true",
      wrap: true,
    },
    {
      name: "Call Time",

      selector: (row) => row?.call_time,
      center: "true",
      wrap: true,
    },
    {
      name: "Agent Name",

      selector: (row) => row?.agent_name,
      center: "true",
      wrap: true,
    },
    {
      name: "Lead",

      selector: (row) => row?.lead,
      center: "true",
      wrap: true,
    },
    {
      name: "Cantact",

      selector: (row) => row?.cantact,
      center: "true",
      wrap: true,
    },
    {
      name: "Status",

      selector: (row) => row?.status,
      center: "true",
      wrap: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => true,
      style: {
        cursor: "pointer",
      },
    },
  ];

  return (
    <>
      <div className="call-lead-details-containner-main">
        {[getCallerIdData]?.map((item) => (
          <div className="call-lead-details-containners-table">
            <div className="call-lead-details-containner">
              <div className="call-lead-details-containner-header">
                <p>Caller ID: {item?.caller_id}</p>
              </div>
              <div className="call-lead-details-containner-header">
                <p>Agent: {item?.agent_name}</p>
              </div>
              <div className="call-lead-details-containner-header">
                <p>Date: {item?.call_date}</p>
              </div>
              <div className="call-lead-details-containner-header">
                <p>Status: {item?.status}</p>
              </div>
            </div>

            <div className="call-lead-details-data-containner">
              <div className="call-lead-details-data-containner-parant">
                <div className="call-lead-details-data-containner-data">
                  <div className="d-flex w-100">
                    <p className="w-50 fw-bold">Start time</p>
                    <p className="w-50">{item?.start_time}</p>
                  </div>
                </div>
                <div className="call-lead-details-data-containner-data">
                  <div className="d-flex w-100">
                    <p className="w-50 fw-bold">End time</p>
                    <p className="w-50">{item?.end_time}</p>
                  </div>
                </div>
                <div className="call-lead-details-data-containner-data">
                  <div className="d-flex w-100">
                    <p className="w-50 fw-bold">Call time</p>
                    <p className="w-50">{item?.call_time}</p>
                  </div>
                </div>
                <div className="call-lead-details-data-containner-data">
                  <div className="d-flex w-100">
                    <p className="w-50 fw-bold">Talk duration</p>
                    <p className="w-50">{item?.talk_duration}</p>
                  </div>
                </div>
                <div className="call-lead-details-data-containner-data">
                  <div className="d-flex w-100">
                    <p className="w-50 fw-bold">Wait time</p>
                    <p className="w-50">{item?.wait_time}</p>
                  </div>
                </div>
              </div>
              <div className="call-lead-details-data-containner-parant">
                <div className="call-lead-details-data-containner-data">
                  <div className="d-flex w-100 px-2">
                    <p className="w-50 fw-bold">Comments:</p>
                  </div>
                  {item?.comment ? (
                    <div className="px-2 show-call-detail-comment-parant">
                      <p className=" show-call-detail-comment">
                        {item?.comment}
                      </p>
                    </div>
                  ) : (
                    <p className="px-2">-</p>
                  )}
                </div>
              </div>
              <div className="call-lead-details-data-containner-parant">
                <div className="call-lead-details-data-containner-data">
                  <div className="d-flex w-100 px-2">
                    <p className="w-50 fw-bold">Recording:</p>
                  </div>
                  {item?.recording ? (
                    !isAudioVisibleDetails ? (
                      <p className="recording-show-details">
                        {isAudioVisibleLoaderDetails ? (
                          <span className="loader"></span>
                        ) : (
                          <i
                            className="fa fa-play cursor-pointer"
                            onClick={() => {
                              handelAudioDetails();
                            }}
                          ></i>
                        )}
                      </p>
                    ) : (
                      <audio
                        src={item?.url_recording}
                        controls
                        autoPlay
                        className="details-audio"
                      />
                    )
                  ) : (
                    <span className="px-2"> - </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="my-3">
          <div className="row w-100 p-0 m-0 ">
            <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
              <CustomSelect
                options={filterOptionsForDataTable[0].agent_filter_option}
                placeholder={"Filter By Agent"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
              <CustomSelect
                options={filterStatusOption[0].status_option}
                placeholder={"Filter By Status"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 mb-2 search-box-in-filter-main">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
                className="w-100 search-box-in-filter-main-input "
              />
              <i className="fa fa-search"></i>
            </div>
          </div>
        </div>

        <div className="py-1">
          <DataTable
            data={filteredData ? filteredData : callLeadData}
            conditionalRowStyles={conditionalRowStyles}
            noDataComponent="No data available"
            onRowClicked={handleRowClicked}
            customStyles={customStyles}
            highlightOnHover
            columns={column}
            noHeader={false}
            persistTableHead
            pagination
            searchable
            responsive
          />
        </div>
      </div>
    </>
  );
};

export default CallLeadList;
