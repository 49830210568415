import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { WebsiteLeads } from "../../../asset/StastucJsonData/WebsiteLeadsJsonData";
import CommonDateRangePicker from "../../Common/DateRangePicker";

const WAChatBotLeadsList = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = WebsiteLeads.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Sr",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
      width: "70px",
    },
    {
      name: "Name",

      selector: (row) => row?.name,
      center: "true",
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      center: "true",
      wrap: true,
      width: "200px",
    },

    {
      name: "Mobile",
      selector: (row) => row?.mobile,
      center: "true",
      wrap: true,
    },
    {
      name: "Channel",
      selector: (row) => row?.chanel,
      center: "true",
      wrap: true,
    },
    {
      name: "Trenscript",
      cell: (row) => (
        <div
          className="text-start cursor-pointer"
          onClick={() => {
            console.log(row?._id);
          }}
        >
          <img src="/svg/link-icon.png" alt="link-img" />
          {/* <button className="btn btn-primary border-none ">Link</button> */}
        </div>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row?.Date_Added,
      center: "true",
      wrap: true,
    },
  ];

  const handleDateRangeApply = (startDate, endDate) => {
    setSelectedDates({ startDate, endDate });
  };

  console.log("selectedDatesaaaaa", selectedDates);

  return (
    <>
      <div className="my-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
            <CommonDateRangePicker onApply={handleDateRangeApply} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
            <button
              type="button"
              className="btn filter_button listing_button px-2 custom-select rounded-0"
              style={{ height: "36px" }}
            >
              <img
                className="mx-2"
                src="/svg/csv_button_icon.png"
                width="25px"
                alt="images"
              />
              Export To CSV
            </button>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2 search-box-in-filter-main">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-100 search-box-in-filter-main-input "
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
      </div>

      <DataTable
        data={filteredData ? filteredData : WebsiteLeads}
        columns={column}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />
    </>
  );
};

export default WAChatBotLeadsList;
