import React from "react";
import HelmetComponent from "./Common/HelmetComponent";

const Viewings = () => {
  return (
    <>
      <HelmetComponent title="Viewings" />
      <div className="d-flex w-100 justify-content-center h-100 align-items-center vh-100">
        Viewings
      </div>
    </>
  );
};

export default Viewings;
