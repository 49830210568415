import React, { useState } from "react";
import CustomDatePicker from "../../Common/DatePicker/DatePickerCommon";
import CustomSelect from "../../Common/Select2Box/customSelectbox";
import DataTable from "react-data-table-component";
import {
  oldLeadDataTableData,
  oldLeadOptionsData,
} from "../../../asset/StastucJsonData/OldLeadJsonData";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import { Link } from "react-router-dom";
import CommonDateRangePicker from "../../Common/DateRangePicker";

const ListOldLead = () => {
  // Define state....
  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });
  const [selectedValue, setSelectedValue] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Define variables....
  const dataTableData = oldLeadDataTableData;
  const OldLeadOptionFilterData = oldLeadOptionsData[0] || [];
  var rowsPerPage = 10;

  // Options select function....
  const handleSelectChange = (option) => {
    setSelectedValue(option);
  };

  // Search functionality for data table....
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = dataTableData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const pageRows = dataTableData.slice((page - 1) * perPage, page * perPage);
    const allPageRowsSelected = pageRows.every((row) =>
      selectedRowIds.includes(row._id)
    );
    setSelectAll(allPageRowsSelected);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  // Handle select box functionality for data table...
  const handleRowCheckboxChange = (rowId) => {
    const isSelected = selectedRowIds.includes(rowId);
    const newSelectedRowIds = isSelected
      ? selectedRowIds.filter((id) => id !== rowId)
      : [...selectedRowIds, rowId];

    setSelectedRowIds(newSelectedRowIds);
    setSelectAll(newSelectedRowIds.length === dataTableData.length);
  };

  const handleSelectAllChange = () => {
    const pageRows = dataTableData.slice(
      (currentPage - 1) * perPage,
      currentPage * perPage
    );
    const pageRowIds = pageRows.map((row) => row._id);

    if (selectAll) {
      setSelectedRowIds(
        selectedRowIds.filter((id) => !pageRowIds.includes(id))
      );
      setSelectAll(false);
    } else {
      setSelectedRowIds([
        ...selectedRowIds,
        ...pageRowIds.filter((id) => !selectedRowIds.includes(id)),
      ]);
      setSelectAll(true);
    }
  };

  // Data table column...
  const column = [
    {
      name: (
        <div className="text-center">
          {/* <span>Sr.</span> */}
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
        </div>
      ),
      width: "100px",
      cell: (row) => (
        <div className="text-center">
          <input
            type="checkbox"
            checked={selectedRowIds.includes(row._id)}
            onChange={() => handleRowCheckboxChange(row._id)}
          />
        </div>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "Date",
      cell: (row) => <div className="line-height-add">{row?.Date_Added}</div>,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Ref No.",
      selector: (row) => row.Ref || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Listing Ref No.",
      selector: (row) => row.Listing_Ref || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row.Type || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.Status || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Substatus",
      selector: (row) => row.Sub_Status || " - ",
      width: "120px",
      center: "true",
      wrap: true,
    },
    {
      name: "Contact Ref",
      selector: (row) => row.Contact_Ref || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Full Name",
      selector: (row) => row.First_name || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Mobile No",
      selector: (row) => row.Mobile_No || " - ",
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.Email_Address || " - ",
      width: "150px",
      center: "true",
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row.Category || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Location",
      selector: (row) => row.Location || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Sublocation",
      cell: (row) => <div className="line-height-add">{row?.Sub_location}</div>,
      width: "170px",
      center: "true",
      wrap: true,
    },
    {
      name: "Agent",
      cell: (row) => <div className="line-height-add">{row?.Agent1}</div>,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Team Leader",
      selector: (row) => row.teamleader || " - ",
      center: "true",
      wrap: true,
    },
    {
      name: "Source",
      selector: (row) => row.Source || " - ",
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "View",
      cell: (row) => (
        <>
          <Link to={`/old-leads/old-lead-details/${row?._id}`}>
            <div className="show-old-lead-action-icon cursor-pointer">
              <img width={"20px;"} src="/svg/eye.png" alt="img" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
      width: "70px",
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <Link to={`/old-leads/edit-old-lead/${row?._id}`}>
            <div className="show-old-lead-action-icon cursor-pointer">
              <img width={"20px;"} src="/svg/pencil.png" alt="img" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
      width: "70px",
    },
  ];

  const handleDateRangeApply = (startDate, endDate) => {
    setSelectedDates({ startDate, endDate });
  };

  console.log("selectedDatesaaaaa", selectedDates);

  return (
    <>
      <div className="pb-6">
        <div className="my-3">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CommonDateRangePicker onApply={handleDateRangeApply} />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_source}
                placeholder={"Filter By Source"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_status}
                placeholder={"Filter By Status"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_sub_status}
                placeholder={"Filter By SubStatus"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_lead_type}
                placeholder={"Filter By Leadtype"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_bed_rooms}
                placeholder={"Filter By Bedrooms"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_agent}
                placeholder={"Filter By Agent"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_team_leader}
                placeholder={"Filter By Team Leader"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_category}
                placeholder={"Filter By Category"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_location}
                placeholder={"Filter By Location"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_sublocation}
                placeholder={"Filter By SubLocation"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_category}
                placeholder={"Select Category"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_agent}
                placeholder={"Select Agent"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_team_leader}
                placeholder={"Select Team Leader"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_status}
                placeholder={"Select Status"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
              <CustomSelect
                options={OldLeadOptionFilterData.filter_by_sub_status}
                placeholder={"Select SubStatus"}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
              {" "}
              <button
                type="button"
                className="btn filter_button listing_button px-2 custom-select rounded-0"
                style={{ height: "36px" }}
              >
                <img
                  className="mx-2"
                  src="/svg/update_field_icon.png"
                  width="25px"
                  alt="images"
                />
                Update Fields
              </button>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-2">
              <button
                type="button"
                className="btn filter_button listing_button px-2 custom-select rounded-0"
                style={{ height: "36px" }}
              >
                <img
                  className="mx-2"
                  src="/svg/csv_button_icon.png"
                  width="25px"
                  alt="images"
                />
                Export To CSV
              </button>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 mb-2 search-box-in-filter-main">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
                className="w-100 search-box-in-filter-main-input "
              />
              <i className="fa fa-search"></i>
            </div>
          </div>
        </div>

        <DataTable
          paginationComponentOptions={paginationComponentOptions}
          data={filteredData ? filteredData : dataTableData}
          paginationPerPage={(perPage, rowsPerPage)}
          paginationTotalRows={filteredData.length}
          onChangeRowsPerPage={handlePerRowsChange}
          noDataComponent="No data available"
          onChangePage={handlePageChange}
          customStyles={customStyles}
          highlightOnHover
          columns={column}
          noHeader={false}
          persistTableHead
          pagination
          searchable
          responsive
        />
      </div>
    </>
  );
};

export default ListOldLead;
