import React, { useEffect, useState } from "react";
import CustomBreadcrumb from "../../../Common/Breadcrumb/customBreadcrumb";
import DataTable from "react-data-table-component";
import { customStyles } from "../../../Common/DataTableStyle/dataTableStyle";
import { Link, useParams } from "react-router-dom";
import { propertyListData } from "../../../../asset/StastucJsonData/PropertyJsonData";
import HelmetComponent from "../../../Common/HelmetComponent";

const OwnersPropertysPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [getIdWiseData, setGetIdWiseData] = useState([]);

  let { id } = useParams();

  const getAgentData = (id) => {
    const findData = propertyListData.filter((item) => item?._id === id);
    setGetIdWiseData(findData);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = getIdWiseData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const column = [
    {
      name: "Ref",
      selector: (row) => row?.Reference,
      center: "true",
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row?.Type,
      center: "true",
      wrap: true,
    },
    {
      name: "Unit",
      selector: (row) => row?.Listings_UnitNo,
      center: "true",
      wrap: true,
    },
    {
      name: "Category",
      selector: (row) => row?.Listings_Category,
      center: "true",
      wrap: true,
    },
    {
      name: "Location",
      selector: (row) => row?.Area_Location,
      center: "true",
      wrap: true,
    },
    {
      name: "Sub Location",
      selector: (row) => row?.Sub_Area_Location,
      center: "true",
      wrap: true,
    },
    {
      name: "Tenancy Start Date",
      selector: (row) => row?.Tenancy_Start_Date,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Tenancy Renewal Date",
      selector: (row) => row?.Tenancy_Renewal_Date,
      center: "true",
      wrap: true,
      width: "150px",
    },
    {
      name: "Statement",
      selector: (row) => row?.Statement,
      center: "true",
      wrap: true,
    },
    {
      name: "Edit",
      cell: (row) => (
        <>
          <Link to={`/owners-edit-property/${row?._id}`}>
            <div className="cursor-pointer">
              <img width={"20px"} src="/svg/pencil.png" alt="images" />
            </div>
          </Link>
        </>
      ),
      center: "true",
      wrap: true,
      width: "60px",
    },
  ];

  useEffect(() => {
    getAgentData(id);
  }, [id]);

  return (
    <>
      <HelmetComponent title="Owner-Property" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title={`Show Property for- ${getIdWiseData[0]?.Agent1}`}
          home="Dashboard"
          currentPage={`Property for- ${getIdWiseData[0]?.Agent1}`}
          addExtraPageTitle="Owners"
          addExtraPageTitlePath="owners"
        />

        <div className="container">
          <div className="my-3">
            <div className="row justify-content-end">
              <div className="col-lg-3 col-md-4 col-sm-12 mb-2 search-box-in-filter-main">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                  className="w-100 search-box-in-filter-main-input "
                />
                <i className="fa fa-search"></i>
              </div>
            </div>
          </div>
          <div className="mt-2 mb-2">
            <DataTable
              data={filteredData ? filteredData : getIdWiseData}
              noDataComponent="No data available"
              customStyles={customStyles}
              highlightOnHover
              noHeader={false}
              persistTableHead
              columns={column}
              pagination
              searchable
              responsive
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnersPropertysPage;
