import * as Yup from "yup";

export const initialValues = {
  rental_id: "",
  agent: "",
  team_leader: "",
  create_by: "",
  associated_agente: "",
  associated_team_leader: "",
  off_plan: "",
  offering_type: "",
  category: "",
  beds: "",
  baths: "",
  emirates: "",
  transaction_number: "",
  project: "",
  location: "",
  sub_location: "",
  is_furnished: "",
  unit_no: "",
  type: "",
  street: "",
  floor: "",
  area: "",
  plot_area: "",
  view: "",
  parking: "",
  owner: "",
  property_title: "",
  description: "",
  price: "",
  freequency: "",
  cheques: "",
  commission: "",
  deposit: "",
  watermark: "",
  photo_image: "",
  floor_plans: "",
  property_status: "",
  source_listing: "",
  expiration_date: "",
  featured: "",
  dewa_number: "",
  str: "",
  available_date: "",
  remind: "",
  key_locations: "",
  rented_at: "",
  rented_until: "",
  maintance_fee: "",
  managed: "",
  exclusive: "",
  invite: "",
  poa: "",
  status: "",
};

export const validationSchema = Yup.object()
  .shape({
    rental_id: Yup.string().required("Rental id is required."),
    agent: Yup.string().required("Agent id is required."),
    team_leader: Yup.string().required("Team leader id is required."),
    create_by: Yup.string().required("Create by is required."),
    associated_agente: Yup.string().required("Associated Agente is required."),
    associated_team_leader: Yup.string().required(
      "Associated team leader is required."
    ),
    off_plan: Yup.string().required("Off plan is required."),
    offering_type: Yup.string().required("Offering plan is required."),
    category: Yup.string().required("Category is required."),
    beds: Yup.string().required("Beds is required."),
    baths: Yup.string().required("Baths is required."),
    emirates: Yup.string().required("Emirates is required."),
    transaction_number: Yup.string().required(
      "Transaction number is required."
    ),
    project: Yup.string().required("Project is required."),
    location: Yup.string().required("Location is required."),
    sub_location: Yup.string().required("Sub location is required."),
    is_furnished: Yup.string().required("Is furnished is required."),
    unit_no: Yup.string().required("Unit no is required."),
    type: Yup.string().required("Type is required."),
    street: Yup.string().required("Street is required."),
    floor: Yup.string().required("Floor is required."),
    area: Yup.string().required("Area is required."),
    plot_area: Yup.string().required("Plot area is required."),
    view: Yup.string().required("View is required."),
    parking: Yup.string().required("Parking is required."),
    owner: Yup.string().required("Owner is required."),
    property_title: Yup.string().required("Property title is required."),
    description: Yup.string().required("Description title is required."),
    price: Yup.string().required("Price title is required."),
    freequency: Yup.string().required("Freequency title is required."),
    cheques: Yup.string().required("Cheques title is required."),
    commission: Yup.string().required("Commission title is required."),
    deposit: Yup.string().required("Deposit title is required."),
    watermark: Yup.string().required("Watermark title is required."),
    photo_image: Yup.string().required("Image title is required."),
    floor_plans: Yup.string().required("Image title is required."),
    property_status: Yup.string().required(
      "Property status title is required."
    ),
    source_listing: Yup.string().required(
      "Source listing title area is required."
    ),
    expiration_date: Yup.string().required(
      "Expiration date title area is required."
    ),
    featured: Yup.string().required("Featured title area is required."),
    dewa_number: Yup.string().required("DEWA number title area is required."),
    str: Yup.string().required("STR title area is required."),
    available_date: Yup.string().required(
      "Available date title area is required."
    ),
    remind: Yup.string().required("remind title area is required."),
    key_locations: Yup.string().required("Key locations is required."),
    property_tenanted: Yup.string().required("Property tenanted is required."),
    rented_at: Yup.string().required("Rented at is required."),
    rented_until: Yup.string().required("Rented until is required."),
    maintance_fee: Yup.string().required("Maintance fee is required."),
    managed: Yup.string().required("Managed fee is required."),
    exclusive: Yup.string().required("Exclusive fee is required."),
    invite: Yup.string().required("Invite fee is required."),
    poa: Yup.string().required("POA is required."),
    status: Yup.string().required("Status is required."),
  })
  .defined();
