import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomBreadcrumb from "../../Common/Breadcrumb/customBreadcrumb";
import { CalendarListData } from "../../../asset/StastucJsonData/CalendarListJsonData";
import DetailComponent from "../../Common/DetailsPageComponent/detailComponent";
import HelmetComponent from "../../Common/HelmetComponent";

const OldContactDetails = () => {
  const [selectData, setSelectData] = useState([]);

  let { id } = useParams();

  const getIdWiseData = (id) => {
    const finData = CalendarListData.find((userID) => id === userID._id);
    setSelectData(finData);
  };

  useEffect(() => {
    getIdWiseData(id);
  }, [id]);

  return (
    <>
      <HelmetComponent title="View-Calendar" />
      <div className="container page-containners-main">
        <CustomBreadcrumb
          title="Calendar Details"
          home="Dashboard"
          addExtraPageTitle="Contacts"
          addExtraPageTitlePath="contacts"
          currentPage="Calendar Details"
        />
        <DetailComponent
          title="Property Ref No. :"
          title1="Event :"
          title2="Purpose of meeting :"
          title3="Status :"
          data={selectData?.property_ref_no}
          data1={selectData?.event}
          data2={selectData?.purpose_of_meeting}
          data3={selectData?.status}
        />
        <DetailComponent
          title="Date :"
          title1="Time :"
          title2="Description :"
          title3=""
          data={selectData?.date}
          data1={selectData?.time}
          data2={selectData?.description}
          data3=""
        />
      </div>
    </>
  );
};

export default OldContactDetails;
