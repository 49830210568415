import React, { useEffect, useRef, useState } from "react";
import headerLogo from "../../../images/headerlogo.png";
// import language from "../../images/language.png";
import { Link } from "react-router-dom";
import "../../../asset/css/common/header.css";
import Modal from "../Model/ModelCommon";
import "../../../asset/css/common/profileModel.css";

const Header = () => {
  // const [searchActive, setSearchActive] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  // const [searchData, setSearchData] = useState("");
  const [menuActive, setMenuActive] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeSubDropdown, setActiveSubDropdown] = useState(null);
  const [logoutModel, setLogoutModel] = useState(false);
  // profile state
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [changePasswordPopup, setChangePasswordPopup] = useState(false);
  const [hideShowCPassword, setHideShowCPassword] = useState(false);
  const [hideShowNPassword, setHideShowNPassword] = useState(false);
  const [hideShowPassword, setHideShowPassword] = useState(false);
  const sidebarMenuRef = useRef(null);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };
  const togglePopupChangePassword = () => {
    setChangePasswordPopup(!changePasswordPopup);
  };

  const handleImageUpload = (e) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const imagePreview = document.getElementById("imagePreview");
        if (imagePreview) {
          imagePreview.style.backgroundImage = `url(${event.target.result})`;
          imagePreview.style.display = "none";
          imagePreview.style.display = "block";
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // const handleInputChangeProfile = (e) => {
  //   const { name, value } = e.target;
  //   setFormValues({ ...formValues, [name]: value });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Perform form submission logic here
  //   console.log(e);
  // };

  // const handleSearchClick = () => {
  //   // setSearchActive(true);
  //   if (searchValue !== "") {
  //     setSearchData(
  //       `You just typed <span style='font-weight: 500;'>${searchValue}</span>`
  //     );
  //   } else {
  //     setSearchData("");
  //   }
  // };

  const handleShowLogoutModel = () => {
    setLogoutModel(true);
  };
  const handleHideLogoutModel = () => {
    setLogoutModel(false);
  };

  // const handleCancelClick = () => {
  //   // setSearchActive(false);
  //   setSearchValue("");
  //   setSearchData("");
  // };

  // const handleInputChange = (e) => {
  //   setSearchValue(e.target.value);
  // };

  // const handleLeftArrowClick = () => {
  //   if (sidebarMenuRef.current) {
  //     const sidebarMenu = sidebarMenuRef.current;
  //     const listItemWidth = sidebarMenu.querySelector("li").offsetWidth;
  //     const scrollAmount = sidebarMenu.scrollLeft - listItemWidth;
  //     sidebarMenu.scrollTo({
  //       left: scrollAmount,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  // const handleRightArrowClick = () => {
  //   if (sidebarMenuRef.current) {
  //     const sidebarMenu = sidebarMenuRef.current;
  //     const listItemWidth = sidebarMenu.querySelector("li").offsetWidth;
  //     const scrollAmount = sidebarMenu.scrollLeft <i className='fas fa-caret-down'></i> listItemWidth;
  //     sidebarMenu.scrollTo({
  //       left: scrollAmount,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };
  // const toggleMenuClose = () => {
  //   if (menuActive) {
  //     setMenuActive(false);
  //   }
  // };
  const profileRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setMenuActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
    setActiveSubDropdown(null);
  };

  const toggleSubDropdown = (index) => {
    setActiveSubDropdown(activeSubDropdown === index ? null : index);
  };

  const handleCloseMiniSidebar = () => {
    setIsopen(false);
  };

  return (
    <>
      <div className="main_head">
        <header className="main_header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 text-start d-flex justify-content-start align-items-center m-0 p-0">
                <Link to={"/"}>
                  <img
                    className="logo-img-header"
                    src={headerLogo}
                    alt="Header Logo"
                  />
                </Link>
                <div className="announcement-bar">
                  <div className="message-container" id="message-container">
                    <div className="message active">
                      <img
                        className="custom_notifyimage"
                        src="../../svg/giftools.gif"
                        alt="gif"
                      />{" "}
                      Welcome Admin
                    </div>
                    <div className="message">
                      <img
                        className="custom_notifyimage"
                        src="../../svg/giftools.gif"
                        alt="gif"
                      />{" "}
                      Welcome Agent
                    </div>
                  </div>
                </div>
              </div>
              <ul className="list-unstyled d-flex justify-content-end align-items-center m-0">
                {/* <li>
                  <div className={`search-box ${searchActive ? "active" : ""}`}>
                    <input
                      type="text"
                      placeholder="Type to search..."
                      value={searchValue}
                      onChange={handleInputChange}
                      className={searchActive ? "active" : ""}
                    />
                    <div
                      className={`search-icon ${searchActive ? "active" : ""}`}
                      onClick={handleSearchClick}
                    >
                      <i className="fas fa-search"></i>
                    </div>
                    <div
                      className={`cancel-icon ${searchActive ? "active" : ""}`}
                      onClick={handleCancelClick}
                    >
                      <i className="fas fa-times"></i>
                    </div>
                    <div
                      className={`search-data ${
                        searchData !== "" ? "active" : ""
                      }`}
                      dangerouslySetInnerHTML={{ __html: searchData }}
                    ></div>
                  </div>
                </li>
                <li className="lang text-white mx-3">
                  <img width={"25px"} src={language} alt="Language" />
                  <span className="lang-txt text-white ms-1">EN</span>
                </li>*/}
                {/* <Link to="/notification">
                  <li className="notification-box me-3 position-relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="#fff"
                      className="bi bi-bell"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                    </svg>
                    <span className="notification-counter">1</span>
                  </li>
                </Link> */}
                <li className="me-2 d-flex align-items-center" ref={profileRef}>
                  <div className="action position-relative">
                    <div
                      className="profile cursor-pointer"
                      onClick={toggleMenu}
                    >
                      <img
                        className="me-2 img-profile-header"
                        width="30"
                        src="/svg/user_profile_iocn.png"
                        alt="user"
                      />
                    </div>
                    <div className={`menu ${menuActive ? "active" : ""}`}>
                      <h3>
                        Binayah
                        <br />
                        <span>crm.binayah.ae</span>
                      </h3>
                      <ul className="m-0 p-0">
                        <li
                          onClick={() => {
                            togglePopup();
                            toggleMenu();
                          }}
                        >
                          {/* <i className="fas fa-user-alt"></i> */}
                          <img
                            src="/svg/my_profile.png"
                            alt="profile"
                            className=""
                          />
                          {/* <a href="#">My Profile</a> */}
                          <span>My Profile</span>
                        </li>
                        <li onClick={togglePopupChangePassword}>
                          {/* <i className="fa fa-lock"></i> */}
                          <img
                            src="/svg/change_password.png"
                            alt="profile"
                            className=""
                          />

                          {/* <a href="#">Change Password</a> */}
                          <span>Change Password</span>
                        </li>
                        <Link to="/notification">
                          <li>
                            {/* <i className="fa fa-inbox"></i> */}
                            <img
                              src="/svg/inbox.png"
                              alt="profile"
                              className=""
                            />
                            <span className="notification-profile-text">
                              Notification
                            </span>
                          </li>
                        </Link>
                        <li>
                          {/* <i className="fa fa-gear"></i> */}
                          <img
                            src="/svg/setting_icon.png"
                            alt="profile"
                            className=""
                          />
                          {/* <a href="#">Settings</a> */}
                          <span>Settings</span>
                        </li>
                        <li
                          // onClick={(handleShowLogoutModel, toggleMenu)}
                          onClick={() => {
                            handleShowLogoutModel();
                            toggleMenu();
                          }}
                        >
                          {/* <i className="fa fa-sign-out"></i> */}
                          <img
                            src="/svg/logout_icon.png"
                            alt="profile"
                            className=""
                          />
                          {/* <a href="#">Logout</a> */}
                          <span>Logout</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="profile_show_name_containner">
                    <span className="cursor-pointer" onClick={toggleMenu}>
                      Binayah Property
                    </span>
                    <br />
                    <span
                      className="admin-name cursor-pointer"
                      onClick={toggleMenu}
                    >
                      Admin{" "}
                      {menuActive ? (
                        <i className="fa fa-angle-up clear-icon"></i>
                      ) : (
                        <i className="fa fa-angle-down clear-icon"></i>
                      )}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <nav
          className={`sidebar-main row align-items-center w-100 m-0 justify-content-center  
          `}
          // ${toFixedHeader ? "sub-header-sticy" : "sub-header-sticy-no"}
          id="sidebar-main"
        >
          <div
            id="sidebar-menu"
            ref={sidebarMenuRef}
            className="col-md-11 custom-scrollbar p-0 m-0"
            style={{ marginLeft: "0px", whiteSpace: "nowrap" }}
          >
            <ul className={`sidebar-links d-flex justify-content-evenly `}>
              <li className="sidebar-list">
                <Link className="sidebar-link sidebar-title link-nav" to="/">
                  <img
                    className="me-2"
                    width="25px"
                    src="/svg/home.svg"
                    alt="Dashboard"
                  />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="sidebar-list">
                <Link
                  className="sidebar-link sidebar-title link-nav"
                  to="all-listing"
                >
                  <img
                    className="me-2"
                    width="25px"
                    src="/svg/listing.svg"
                    alt="All Listing"
                  />
                  <span>All Listing</span>
                </Link>
              </li>
              <li className="sidebar-list navbar-dropdown">
                <a className="sidebar-link sidebar-title link-nav" href="#">
                  <img
                    className="me-2"
                    width="25px"
                    src="/svg/working.png"
                    alt="Property Subtype"
                  />
                  <span>
                    Work <i className="fas fa-caret-down"></i>
                  </span>
                </a>
                <div className="dropdown">
                  <Link
                    className="sidebar-link sidebar-title link-nav"
                    to="all-leads"
                  >
                    <span>
                      All Leads <i className="fas fa-caret-down"></i>
                    </span>
                  </Link>
                  <div className="dropdown sub-dropdown">
                    <Link to="callleads">Call Leads</Link>
                    <Link to="propertyfinder-leads">PropertyFinder Leads</Link>
                    <Link to="bayut-leads">Bayut Leads</Link>
                    <Link to="dubizzle-leads">Dubizzle Leads</Link>
                    <Link to="website-leads">Website Leads</Link>
                    <Link to="wa-chatbot-leads">WA Chat Bot Leads</Link>
                    <Link to="live-chat-leads">Live Chat Leads</Link>
                    <Link to="paid-leads1">Paid Leads1</Link>
                    <Link to="paid-leads2">Paid Leads2</Link>
                    <Link to="old-leads">Old Leads</Link>
                    <Link to="leads-pool">Leads Pool</Link>
                    <Link to="leads-settings">Leads Settings</Link>
                  </div>
                </div>
              </li>
              <li className="sidebar-list navbar-dropdown">
                <a className="sidebar-link sidebar-title link-nav" href="#">
                  <img
                    className="me-2"
                    width="25px"
                    src="/svg/contact.svg"
                    alt="Contacts"
                  />
                  <span>
                    Contacts <i className="fas fa-caret-down"></i>
                  </span>
                </a>
                <div className="dropdown">
                  <Link to="contacts">Manage Contact</Link>
                  <Link to="old-contact">Old Contact</Link>
                  <Link to="calendar">Calendar</Link>
                  <div className="navbar-dropdown p-0">
                    <a href="#">
                      Deals <i className="fas fa-caret-down"></i>
                    </a>
                    <div className="dropdown" style={{ top: "85px" }}>
                      <Link to="all-deals">All Deals</Link>
                      <Link to="old-deals">Old Deals</Link>
                    </div>
                  </div>
                  <Link to="viewings">Viewings</Link>
                </div>
              </li>
              <li className="sidebar-list navbar-dropdown">
                <a className="sidebar-link sidebar-title link-nav" href="#">
                  <img
                    className="me-2"
                    width="25px"
                    src="/svg/admin-icon.png"
                    alt="Admin"
                  />
                  <span>
                    Admin <i className="fas fa-caret-down"></i>
                  </span>
                </a>
                <div className="dropdown">
                  <Link to="portals">Portals</Link>
                  <Link to="agents">Agents</Link>
                  <Link to="team-leaders">Team Leaders</Link>
                  <Link to="role">Role Management</Link>
                  <Link to="profile">Profile</Link>
                  <Link to="hrm">HRM</Link>
                </div>
              </li>
              <li className="sidebar-list navbar-dropdown">
                <a className="sidebar-link sidebar-title link-nav" href="#">
                  <img
                    className="me-2"
                    width="25px"
                    src="/svg/analysis.png"
                    alt="Analytics"
                  />
                  <span>
                    Analytics <i className="fas fa-caret-down"></i>
                  </span>
                </a>
                <div className="dropdown">
                  <Link to="history">History</Link>
                  <Link to="reports">Reports</Link>
                </div>
              </li>
              <li className="sidebar-list navbar-dropdown">
                <a className="sidebar-link sidebar-title link-nav" href="#">
                  <img
                    className="me-2"
                    width="25px"
                    src="/svg/office-building.png"
                    alt="Company"
                  />
                  <span>
                    Company <i className="fas fa-caret-down"></i>
                  </span>
                </a>
                <div className="dropdown">
                  <a href="#">Notice Board</a>
                  <a href="#">Push Notification</a>
                </div>
              </li>
              <li className="sidebar-list navbar-dropdown">
                <a className="sidebar-link sidebar-title link-nav" href="#">
                  <img
                    className="me-2"
                    width="25px"
                    src="/svg/database.png"
                    alt="Data"
                  />
                  <span>
                    Data <i className="fas fa-caret-down"></i>
                  </span>
                </a>
                <div className="dropdown">
                  <Link to="location">Location</Link>
                  <Link to="sub-location">Sub Location</Link>
                  <Link to="projects">Projects</Link>
                  <Link to="features">Features Master</Link>
                  <Link to="tenants">Tenants</Link>
                  <Link to="owners">Owners</Link>
                  <div className="sub-dropdown">
                    <a href="#" className="create_sales_offer_main">
                      Create Sales Offer
                    </a>
                    <div className="sub-dropdown-content">
                      <Link to="developer-logo">Developer Logo</Link>
                      <Link to="create-project">Create Project</Link>
                      <Link to="sales-offer">Sales Offer</Link>
                    </div>
                  </div>
                  <Link to="public-folder">Public Folder</Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div className="mini-header pb-4">
        <div className="container-fluid p-0 mini-header-sub-containner">
          <nav className="navbar navbar-expand-lg navbar-light  shadow-md">
            <div className="container-fluid px-3">
              <div className="navbar-brand text-primary mr-0">
                <Link to={`/`}>
                  <img
                    className="mini-logo-img"
                    src={headerLogo}
                    alt="logo-img"
                  />
                </Link>
              </div>

              <div className="form-inline ml-auto">
                <div className="btn btn-primary" onClick={ToggleSidebar}>
                  <i className="fa fa-bars"></i>
                </div>
              </div>
            </div>
          </nav>
          <div className={`sidebar ${isOpen == true ? "active" : ""}`}>
            <div className="sd-header">
              <div className="mb-0">
                <img
                  className="mini-logo-img"
                  src={headerLogo}
                  alt="logo-img"
                />
              </div>
              <div className="d-flex">
                <ul className="list-unstyled d-flex justify-content-end align-items-center m-0">
                  {/* <Link to="/notification">
                    <li className="notification-box me-3 position-relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#fff"
                        className="bi bi-bell"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                      </svg>
                      <span className="notification-counter">1</span>
                    </li>
                  </Link> */}
                  <li className="me-2">
                    <div className="action position-relative">
                      <div className="profile" onClick={toggleMenu}>
                        <img
                          className="me-2"
                          width="30"
                          src="/svg/user_profile_iocn.png"
                          alt="user"
                        />
                      </div>
                      <div
                        className={`menu mini-header-profile-menu ${
                          menuActive ? "active" : ""
                        }`}
                      >
                        <h3>
                          Binayah
                          <br />
                          <span>crm.binayah.ae</span>
                        </h3>
                        <ul className="m-0 p-0">
                          <li onClick={togglePopup}>
                            <img
                              src="/svg/my_profile.png"
                              alt="profile"
                              className=""
                            />
                            <a href="#">My Profile</a>
                          </li>
                          <li onClick={togglePopupChangePassword}>
                            <img
                              src="/svg/change_password.png"
                              alt="profile"
                              className=""
                            />
                            <a href="#">Change Password</a>
                          </li>
                          <Link to="/notification">
                            <li>
                              <img
                                src="/svg/inbox.png"
                                alt="profile"
                                className=""
                              />
                              <span className="notification-profile-text">
                                Notification
                              </span>
                            </li>
                          </Link>
                          <li>
                            <img
                              src="/svg/setting_icon.png"
                              alt="profile"
                              className=""
                            />
                            <a href="#">Settings</a>
                          </li>
                          <li onClick={handleShowLogoutModel}>
                            <img
                              src="/svg/logout_icon.png"
                              alt="profile"
                              className=""
                            />
                            <a href="#">Logout</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="btn btn-primary" onClick={ToggleSidebar}>
                  <i className="fa fa-times"></i>
                </div>
              </div>
            </div>
            <div className="sd-body pb-7">
              <ul className="vh-100 h-100">
                <li onClick={handleCloseMiniSidebar}>
                  <Link className="" to="/">
                    <span className="sd-link">Dashboard</span>
                  </Link>
                </li>
                <li onClick={handleCloseMiniSidebar}>
                  <Link className="" to="/all-listing">
                    <span className="sd-link">All Listing</span>
                  </Link>
                </li>
                <li className="worl-parant-menu mb-0">
                  <a className="sd-link" onClick={() => toggleDropdown(0)}>
                    Work
                    <i
                      className={`float-end fa ${
                        activeDropdown === 0
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </a>
                  {activeDropdown === 0 && (
                    <ul className="dropdown-menu worl-parant-menu-chield shadow-none">
                      <li className="mb-0">
                        <Link
                          to={"/all-leads"}
                          className=""
                          onClick={() => toggleSubDropdown(0)}
                        >
                          <span className="sd-link pl-5 options-works">
                            All Lead
                            <i
                              className={`float-end fa ${
                                activeSubDropdown === 0
                                  ? "fa-chevron-up"
                                  : "fa-chevron-down"
                              }`}
                            ></i>
                          </span>
                        </Link>
                        {activeSubDropdown === 0 && (
                          <ul className="sub-dropdown-menu ">
                            <li onClick={handleCloseMiniSidebar}>
                              <Link className="dropdown-item" to="callleads">
                                <span className="sd-link">Call Leads</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link
                                className="dropdown-item"
                                to="propertyfinder-leads"
                              >
                                <span className="sd-link">
                                  PropertyFinder Leads
                                </span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link className="dropdown-item" to="bayut-leads">
                                <span className="sd-link">Bayut Leads</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link
                                className="dropdown-item"
                                to="dubizzle-leads"
                              >
                                <span className="sd-link">Dubizzle Leads</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link
                                className="dropdown-item"
                                to="website-leads"
                              >
                                <span className="sd-link">Website Leads</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link
                                className="dropdown-item"
                                to="wa-chatbot-leads"
                              >
                                <span className="sd-link">
                                  WA Chat Bot Leads
                                </span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link
                                className="dropdown-item"
                                to="live-chat-leads"
                              >
                                <span className="sd-link">Live Chat Leads</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link className="dropdown-item" to="paid-leads1">
                                <span className="sd-link">Paid Leads1</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link className="dropdown-item" to="paid-leads2">
                                <span className="sd-link">Paid Leads2</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link className="dropdown-item" to="old-leads">
                                <span className="sd-link">Old Leads</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link className="dropdown-item" to="leads-pool">
                                <span className="sd-link">Leads Pool</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link
                                className="dropdown-item"
                                to="leads-settings"
                              >
                                <span className="sd-link">Leads Settings</span>
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                    </ul>
                  )}
                </li>
                <li className="worl-parant-menu mb-0">
                  <a className="sd-link" onClick={() => toggleDropdown(1)}>
                    Contacts
                    <i
                      className={`float-end fa ${
                        activeDropdown === 1
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </a>
                  {activeDropdown === 1 && (
                    <ul className="dropdown-menu worl-parant-menu-chield add-sub-menu-hover">
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/contacts">
                          <span className="sd-link pl-5 options-works">
                            Manage Contact
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/old-contact">
                          <span className="sd-link pl-5 options-works">
                            Old Contact
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/calendar">
                          <span className="sd-link pl-5 options-works">
                            Calendar
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0">
                        <a className="" onClick={() => toggleSubDropdown(1)}>
                          <span className="sd-link pl-5 options-works">
                            Deals
                            <i
                              className={`float-end fa ${
                                activeSubDropdown === 1
                                  ? "fa-chevron-up"
                                  : "fa-chevron-down"
                              }`}
                            ></i>
                          </span>
                        </a>

                        {activeSubDropdown === 1 && (
                          <ul className="sub-dropdown-menu">
                            <li onClick={handleCloseMiniSidebar}>
                              <Link className="dropdown-item" to="/all-deals">
                                <span className="sd-link">All Deals</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link className="dropdown-item" to="/old-deals">
                                <span className="sd-link">Old Deals</span>
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/viewings">
                          <span className="sd-link pl-5 options-works">
                            Viewings
                          </span>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="worl-parant-menu mb-0">
                  <a className="sd-link" onClick={() => toggleDropdown(2)}>
                    Admin
                    <i
                      className={`float-end fa ${
                        activeDropdown === 2
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </a>
                  {activeDropdown === 2 && (
                    <ul className="dropdown-menu worl-parant-menu-chield add-sub-menu-hover">
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/portals">
                          <span className="sd-link pl-5 options-works">
                            Portals
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/agents">
                          <span className="sd-link pl-5 options-works">
                            Agents
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/team-leaders">
                          <span className="sd-link pl-5 options-works">
                            Team Leaders
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/role">
                          <span className="sd-link pl-5 options-works">
                            Role Management
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/profile">
                          <span className="sd-link pl-5 options-works">
                            Profile
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/hrm">
                          <span className="sd-link pl-5 options-works">
                            HRM
                          </span>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="worl-parant-menu mb-0">
                  <a className="sd-link" onClick={() => toggleDropdown(3)}>
                    Analytics
                    <i
                      className={`float-end fa ${
                        activeDropdown === 3
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </a>
                  {activeDropdown === 3 && (
                    <ul className="dropdown-menu worl-parant-menu-chield add-sub-menu-hover">
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/history">
                          <span className="sd-link pl-5 options-works">
                            History
                          </span>
                        </Link>
                      </li>

                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/reports">
                          <span className="sd-link pl-5 options-works">
                            Reports
                          </span>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="worl-parant-menu mb-0">
                  <a className="sd-link" onClick={() => toggleDropdown(4)}>
                    Company
                    <i
                      className={`float-end fa ${
                        activeDropdown === 4
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </a>
                  {activeDropdown === 4 && (
                    <ul className="dropdown-menu worl-parant-menu-chield add-sub-menu-hover">
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="#">
                          <span className="sd-link pl-5 options-works">
                            Notice Board
                          </span>
                        </Link>
                      </li>

                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="#">
                          <span className="sd-link pl-5 options-works">
                            Push Notification
                          </span>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className="worl-parant-menu mb-0">
                  <a className="sd-link" onClick={() => toggleDropdown(5)}>
                    Data
                    <i
                      className={`float-end fa ${
                        activeDropdown === 5
                          ? "fa-chevron-up"
                          : "fa-chevron-down"
                      }`}
                    ></i>
                  </a>
                  {activeDropdown === 5 && (
                    <ul className="dropdown-menu worl-parant-menu-chield add-sub-menu-hover vh-100">
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/location">
                          <span className="sd-link pl-5 options-works">
                            Location
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/sub-location">
                          <span className="sd-link pl-5 options-works">
                            Sub Location
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/projects">
                          <span className="sd-link pl-5 options-works">
                            Projects
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/features">
                          <span className="sd-link pl-5 options-works">
                            Features
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/tenants">
                          <span className="sd-link pl-5 options-works">
                            Tenants
                          </span>
                        </Link>
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/owners">
                          <span className="sd-link pl-5 options-works">
                            Owners
                          </span>
                        </Link>
                      </li>

                      <li className="mb-0">
                        <a className="" onClick={() => toggleSubDropdown(5)}>
                          <span className="sd-link pl-5 options-works">
                            Create Sales Offer
                            <i
                              className={`float-end fa ${
                                activeSubDropdown === 5
                                  ? "fa-chevron-up"
                                  : "fa-chevron-down"
                              }`}
                            ></i>
                          </span>
                        </a>

                        {activeSubDropdown === 5 && (
                          <ul className="sub-dropdown-menu">
                            <li onClick={handleCloseMiniSidebar}>
                              <Link
                                className="dropdown-item"
                                to="/developer-logo"
                              >
                                <span className="sd-link">Developer Logo</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link
                                className="dropdown-item"
                                to="/create-project"
                              >
                                <span className="sd-link">Create Project</span>
                              </Link>
                            </li>
                            <li onClick={handleCloseMiniSidebar}>
                              <Link className="dropdown-item" to="/sales-offer">
                                <span className="sd-link">Sales Offer</span>
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li className="mb-0" onClick={handleCloseMiniSidebar}>
                        <Link className="" to="/public-folder">
                          <span className="sd-link pl-5 options-works">
                            Public Folder
                          </span>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div
            className={`sidebar-overlay ${isOpen == true ? "active" : ""}`}
            onClick={ToggleSidebar}
          ></div>
        </div>
      </div>
      <div className="model_inner">
        <Modal show={logoutModel} handleClose={handleHideLogoutModel}>
          <div className="sdvds">
            <h5 className="mb-5">Logout</h5>
            <div>Are you sure you want to log out?</div>
          </div>
          <div>
            <div className="text-center mt-4 py-2 gap-2">
              <Link to={"login"}>
                <button
                  type="submit"
                  className="btn btn-secondary mx-2 success-btn"
                  onClick={handleHideLogoutModel}
                >
                  Yes
                </button>
              </Link>
              <button
                type="submit"
                className="btn btn-secondary success-btn"
                onClick={handleHideLogoutModel}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </div>
      {/* profile model.. */}
      <div>
        {isPopupVisible && (
          <div className="profile-model-header">
            <div className="container">
              <div id="myPopup" className="popup">
                <div className="popup-header">
                  <h1>Update Profile</h1>
                  <span className="close toggle" onClick={togglePopup}>
                    close
                  </span>
                </div>
                <div className="container">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="imageUpload">
                        <img
                          src="/svg/profile_camera.png"
                          width="20px"
                          alt="Upload"
                        />
                      </label>
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="imagePreview"
                        style={{
                          backgroundImage:
                            "url('http://i.pravatar.cc/500?img=7')",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="edit_form">
                    <form>
                      <div className="name_email gap-3">
                        <div className="input-field-popup">
                          <input
                            type="text"
                            placeholder="Enter Name"
                            className=""
                          />
                          <img
                            src="/svg/user_name_login_icon.png"
                            alt=""
                            className="user_name_imgs"
                            width="20px"
                          />
                        </div>
                        <div className="input-field-popup-email">
                          <input
                            type="email"
                            placeholder="Enter Email"
                            className="w-100 pl-3"
                          />
                          <img
                            src="/svg/inbox.png"
                            alt=""
                            className="user_email_imgs"
                            width="20px"
                          />
                        </div>
                      </div>
                      <div className="phone_number">
                        <input
                          type="text"
                          placeholder="Enter Phone Number"
                          className="phone_number_input"
                        />
                        <img
                          src="/svg/phone.png"
                          alt=""
                          className="phone_number_img"
                          width="20px"
                        />
                      </div>
                      <div className="submit_button">
                        <input type="submit" placeholder="Submit" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Change Passwor model.. */}
      <div>
        {changePasswordPopup && (
          <div className="profile-model-header">
            <div className="container">
              <div id="myPopup" className="popup">
                <div className="popup-header my-3">
                  <h1>Change Password</h1>
                  <span
                    className="close toggle"
                    onClick={togglePopupChangePassword}
                  >
                    close
                  </span>
                </div>
                <div className="container">
                  <div className="edit_form">
                    <form>
                      <div className="name_email">
                        <div className="input-field-popup">
                          <input
                            type={hideShowPassword ? "text" : "password"}
                            placeholder="Current Password"
                            className=""
                          />
                          <img
                            src="/svg/login_pass_icon.png"
                            alt=""
                            className="user_name_imgs"
                            width="20px"
                          />
                          <img
                            width="20px"
                            src={
                              hideShowPassword
                                ? "/svg/password_view.png"
                                : "/svg/password_hide.png"
                            }
                            className="password-icon-hide-show"
                            alt="images"
                            onClick={() => {
                              setHideShowPassword(!hideShowPassword);
                            }}
                          />
                        </div>
                        <div className="input-field-popup-email">
                          <input
                            type={hideShowNPassword ? "text" : "password"}
                            placeholder="New Password"
                            className="w-100 pl-3"
                          />
                          <img
                            src="/svg/login_pass_icon.png"
                            alt=""
                            className="user_email_imgs"
                            width="20px"
                          />
                          <img
                            width="20px"
                            src={
                              hideShowNPassword
                                ? "/svg/password_view.png"
                                : "/svg/password_hide.png"
                            }
                            className="password-icon-hide-show"
                            alt="images"
                            onClick={() => {
                              setHideShowNPassword(!hideShowNPassword);
                            }}
                          />
                        </div>
                      </div>
                      <div className="phone_number">
                        <input
                          type={hideShowCPassword ? "text" : "password"}
                          placeholder="Confirm Password"
                          className="phone_number_input"
                        />
                        <img
                          src="/svg/login_pass_icon.png"
                          alt=""
                          className="phone_number_img"
                          width="20px"
                        />
                        <img
                          width="20px"
                          src={
                            hideShowCPassword
                              ? "/svg/password_view.png"
                              : "/svg/password_hide.png"
                          }
                          className="password-icon-hide-show"
                          alt="images"
                          onClick={() => {
                            setHideShowCPassword(!hideShowCPassword);
                          }}
                        />
                      </div>
                      <div className="submit_button my-3">
                        <input type="submit" placeholder="Submit" />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
