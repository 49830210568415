import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { locationListData } from "../../../asset/StastucJsonData/LocationJsonData";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import Modal from "../../Common/Model/ModelCommon";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FormikSubmitButton } from "../../Common/FormikInputFields/CommonFromikFields";

const LocationList = () => {
  // declare state...
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModel, setShowAddModel] = useState(false);
  const [showEditModel, setShowEditModel] = useState(false);
  const [getDataIdWise, setGetDataIdWise] = useState([]);
  const [selectId, setSelectId] = useState();

  const formRef = useRef();

  // validation...
  const validationSchema = Yup.object({
    location: Yup.string().required("Please location is required."),
  });

  // Add submit form...

  const handleAddLocationSubmit = (values) => {
    if (values) {
      locationListData.push({
        _id: "54amsajg943oakc",
        location: values.location,
      });
      setShowAddModel(false);
    }
  };
  // Edit submit form...
  const handleEditLocationSubmit = (values) => {
    // const updateLocation = getDataIdWise.map((item) => {
    //   if (item._id === selectId) {
    //     return { ...item, location: values.location };
    //   }
    //   return item;
    // });
    // setGetDataIdWise(updateLocation);
    console.log("updateLocation", values);
    setShowEditModel(false);
  };

  // Hendle search functionality...
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = locationListData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Add and Edit location model close functionality....
  const handleCloseAddModel = () => {
    setShowAddModel(false);
  };
  const handleCloseEditModel = () => {
    setShowEditModel(false);
  };

  // Get data id wise...

  const getIdWiseData = (id) => {
    const findData = locationListData.find((user_id) => id === user_id?._id);
    setGetDataIdWise(findData);
  };

  // Use Effect render function...
  useEffect(() => {
    getIdWiseData(selectId);
  }, [selectId]);

  const column = [
    {
      name: "Sr.",
      selector: (row, index) => index + 1,
      width: "100px",
      center: "true",
      wrap: true,
    },
    {
      name: "Location",
      cell: (row) => <div className="pl-2">{row?.location}</div>,
      center: false,
      wrap: true,
      minWidth: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div
            onClick={() => {
              setShowEditModel(true);
              setSelectId(row?._id);
            }}
            className="cursor-pointer"
          >
            <img width={"20px"} src="/svg/pencil.png" alt="img" />
          </div>
        </>
      ),
      center: "true",
      wrap: true,
      width: "100px",
    },
  ];

  return (
    <>
      <div className="d-flex datatable-header-main">
        <button
          type="button"
          className="btn filter_button add_button rounded-0"
          onClick={() => setShowAddModel(true)}
        >
          <img
            className="mx-2"
            src="/svg/pluse_icon_button.png"
            width={"20px"}
            alt="images"
          />
          Add Location
        </button>
        <div className="searchbox-parant-main ">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search_box rounded-0"
          />
          <i className="fa fa-search"></i>
        </div>
      </div>

      <DataTable
        columns={column}
        data={filteredData ? filteredData : locationListData}
        customStyles={customStyles}
        fixedHeader={true}
        noHeader={false}
        noDataComponent="No data available"
        pagination
        searchable
        responsive
        highlightOnHover
        persistTableHead
      />

      {/* Add model */}
      <div className="model_inner">
        <Modal show={showAddModel} handleClose={handleCloseAddModel}>
          <div className="sdvds">
            <h5 className="mb-5">Add Location</h5>
            <Formik
              initialValues={{ location: "" }}
              validationSchema={validationSchema}
              onSubmit={handleAddLocationSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Location</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter location"
                      name="location"
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="text-center">
                    <FormikSubmitButton />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>

      {/* Edit Model */}
      <div className="model_inner">
        <Modal show={showEditModel} handleClose={handleCloseEditModel}>
          <div className="sdvds">
            <h5 className="mb-5">Edit Location</h5>
            <Formik
              innerRef={formRef}
              initialValues={{ location: getDataIdWise?.location || "" }}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={handleEditLocationSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="w-100 mb-3">
                    <p className="m-0 fw-bold">Location</p>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder="Enter location"
                      name="location"
                    />
                    <ErrorMessage
                      name="location"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="text-center">
                    <FormikSubmitButton />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default LocationList;
