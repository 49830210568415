export const customStyles = {
  header: {
    style: {
      fontSize: "14px",
      color: "#202124",
      minHeight: "50px",
      maxHeight: "auto",
      paddingLeft: "5px",
      paddingRight: "5px",
      textAlign: "center",
      fontFamily: "memv",
      whiteSpace: "unset",
      overflow: "wrap",
      overflowWrap: "break-word",
    },
  },
  headRow: {
    style: {
      background: "linear-gradient(132deg, #004e41, #004e41, #004e41)",
      minHeight: "50px",
      borderBottomWidth: "1px",
      borderBottomColor: "#e3e3e3",
      borderBottomStyle: "solid",
      color: "#fff",
      fontFamily: "memv",
    },
  },
  headCells: {
    style: {
      fontSize: "12px",
      fontWeight: "500",
      textTransform: "uppercase",
      paddingLeft: "5px",
      paddingRight: "5px",
      whiteSpace: "nowrap",
      border: "1px solid #e3e3e3",
      textAlign: "center",
      wordWrap: "break-word",
      fontFamily: "memv",
    },
  },
  rows: {
    style: {
      minHeight: "40px",
      backgroundColor: "#fff",
      fontFamily: "memv",
      "&:nth-child(2n)": {
        backgroundColor: "#f9f9f9",
      },
      "&:not(:last-of-type)": {
        borderBottomWidth: "1px",
        borderBottomColor: "#e3e3e3",
        borderBottomStyle: "solid",
      },
    },
  },
  cells: {
    style: {
      fontSize: "13px",
      paddingLeft: "5px",
      paddingRight: "5px",
      wordWrap: "break-word",
      whiteSpace: "normal",
      border: "1px solid #e3e3e3",
      textAlign: "center",
      fontFamily: "memv",
    },
  },
};
