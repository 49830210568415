import React, { useState } from "react";
import "../../../../asset/css/pages/agentPermission.css";

const PermissionTeamLead = () => {
  const [checkedItems, setCheckedItems] = useState({});

  const modules = [
    { id: 1, name: "Rentals" },
    { id: 2, name: "Sales" },
    { id: 3, name: "Leads" },
    { id: 4, name: "Old Leads" },
    { id: 5, name: "Deals" },
    { id: 6, name: "Old Deals" },
    { id: 7, name: "Manage Tenant" },
    { id: 8, name: "Manage Owner" },
    { id: 9, name: "Agent's Folder" },
    { id: 10, name: "Calendar" },
  ];

  const handleCheckboxChange = (moduleId, permission) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [moduleId]: {
        ...prevState[moduleId],
        [permission]: !prevState[moduleId]?.[permission],
      },
    }));
  };

  const handleSubmit = () => {
    console.log("Checked Items:", checkedItems);
  };
  return (
    <>
      <div className=" my-4">
        <div className="table-responsive">
          <table className="table table-bordered table-hover align-middle agent-permition-table-main-containner">
            <thead className="">
              <tr>
                <th className="text-center set-permition-column-with">SR.</th>
                <th className="text-center">MODULE</th>
                <th className="text-center set-permition-column-with">VIEW</th>
                <th className="text-center set-permition-column-with">ADD</th>
                <th className="text-center set-permition-column-with">EDIT</th>
                <th className="text-center set-permition-column-with">
                  DELETE
                </th>
              </tr>
            </thead>
            <tbody>
              {modules.map((module) => (
                <tr key={module.id}>
                  <td className="text-center">{module.id}</td>
                  <td>{module.name}</td>
                  {["view", "add", "edit", "delete"].map((permission) => (
                    <td className="text-center" key={permission}>
                      <div>
                        <input
                          type="checkbox"
                          checked={
                            checkedItems[module.id]?.[permission] || false
                          }
                          onChange={() =>
                            handleCheckboxChange(module.id, permission)
                          }
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-100 text-center">
          <button className="success-btn mx-2" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default PermissionTeamLead;
