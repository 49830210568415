import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { BayutLeadsData } from "../../../asset/StastucJsonData/BayutLeadsJson";
import { customStyles } from "../../Common/DataTableStyle/dataTableStyle";
import CommonDateRangePicker from "../../Common/DateRangePicker";

const ListBayutLeads = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDates, setSelectedDates] = useState({
    startDate: "",
    endDate: "",
  });

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Search functionality for data table....

  const filteredData = BayutLeadsData.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
  const column = [
    {
      name: "SR",
      width: "50px",
      selector: (row, index) => index + 1,
      center: "true",
      wrap: true,
    },
    {
      name: "Property Id",
      width: "200px",
      cell: (row) => <div className="text-start">{row?.property_id}</div>,
      center: "true",
      wrap: true,
    },
    {
      name: "Property Refrance",
      width: "150px",
      selector: (row) => row?.property_refrance,
      center: "true",
      wrap: true,
    },
    {
      name: "Currant Type",
      width: "",
      selector: (row) => row?.currant_type,
      center: "true",
      wrap: true,
    },
    {
      name: "Message",
      width: "300px",
      cell: (row) => (
        <div className="text-start py-2">
          <div className="p-1">{row?.message}</div>
          <div className="p-1">
            <span className="fw-bold">Link:</span>
            <a
              className="link-bayut-leads-data-table"
              href={row.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {row?.link}
            </a>
          </div>
          <div className="p-1">
            <span className="fw-bold">Refrance No. :</span>
            <span>{row?.property_refrance}</span>{" "}
          </div>
        </div>
      ),
      center: "true",
      wrap: true,
    },
    {
      name: "Name",
      width: "",
      selector: (row) => row?.name,
      center: "true",
      wrap: true,
    },
    {
      name: "Email",
      width: "",
      selector: (row) => row?.email,
      center: "true",
      wrap: true,
    },
    {
      name: "Type",
      width: "",
      selector: (row) => row?.type,
      center: "true",
      wrap: true,
    },
    {
      name: "Date",
      width: "",
      selector: (row) => row?.date,
      center: "true",
      wrap: true,
    },
  ];
  const handleDateRangeApply = (startDate, endDate) => {
    setSelectedDates({ startDate, endDate });
  };

  console.log("selectedDatesaaaaa", selectedDates);
  return (
    <>
      <div className="my-3">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2">
            <CommonDateRangePicker onApply={handleDateRangeApply} />
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 mb-2 search-box-in-filter-main">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-100 search-box-in-filter-main-input "
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
      </div>
      <div className="py-1">
        <DataTable
          data={filteredData ? filteredData : BayutLeadsData}
          noDataComponent="No data available"
          customStyles={customStyles}
          columns={column}
          noHeader={false}
          highlightOnHover
          persistTableHead
          pagination
          searchable
          responsive
        />
      </div>
    </>
  );
};

export default ListBayutLeads;
