import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Header from "./Compnent/Common/Header/Header";
import Footer from "./Compnent/Common/Footer/Footer";
import Home from "./Compnent/Admin/Dashbord/Home";

import AllLeads from "./Compnent/Admin/AllLead";
import AddAllLeadPage from "./Compnent/Admin/AllLead/AddAllLead/index";
import EditAllLeadPage from "./Compnent/Admin/AllLead/EditAllLead/index";
import CallLeads from "./Compnent/Admin/CallLead";
import PropertyFinderLeads from "./Compnent/Admin/PropertyFinderLeads";
import BayutLeads from "./Compnent/Admin/BayutLeads";
import DubizzleLeads from "./Compnent/Admin/DubizzleLeads";
import WebsiteLeads from "./Compnent/Admin/WebsiteLeads/index";
import WAChatBotLeads from "./Compnent/Admin/WAChatBotLeads/index";
import LiveChatLeads from "./Compnent/Admin/LiveChatLeads/index";
import PaidLeads1 from "./Compnent/Admin/PaidLeads1/index";
import PaidLeads2 from "./Compnent/Admin/PaidLeads2/index";
import OldLeads from "./Compnent/Admin/OldLead/index";
import LeadsPool from "./Compnent/LeadsPool";
import OldDeals from "./Compnent/Admin/OldDeals/index";
import Viewings from "./Compnent/Viewings";
import Profile from "./Compnent/Profile";
import HRM from "./Compnent/HRM";
import Alllisting from "./Compnent/Admin/AllListing";
import AddAllListingPage from "./Compnent/Admin/AllListing/AddAllListing/index";
import EditAllListingPage from "./Compnent/Admin/AllListing/EditAllListing/index";
import Location from "./Compnent/Admin/Location";
import SubLocation from "./Compnent/Admin/SubLocation";
import Project from "./Compnent/Admin/Project";
import OldLeadDetails from "./Compnent/Admin/OldLead/OldLeadDetails";
import EditOldLead from "./Compnent/Admin/OldLead/EditOldLead";
import Contacts from "./Compnent/Admin/Contacts";
import OldContacts from "./Compnent/Admin/OldContacts";
import OldContactDetails from "./Compnent/Admin/OldContacts/OldContactDetails";
import Calendars from "./Compnent/Admin/Calendars";
import CalendersDetails from "./Compnent/Admin/Calendars/CalendersDetails";
import LeadsSettings from "./Compnent/Admin/LeadsSettings";
import AllDeals from "./Compnent/Admin/AllDeals";
import AllDealsDetails from "./Compnent/Admin/AllDeals/AllDealsDetailsPage/index";
import AddAllDeals from "./Compnent/Admin/AllDeals/AddAllDealsPage/index";
import EditAllDealsPage from "./Compnent/Admin/AllDeals/EditAllDealsPage/index";
import OldDealsDetailsPage from "./Compnent/Admin/OldDeals/OldDealsDetailsPage/index";
import TenantsPage from "./Compnent/Admin/Tenants/index";
import AddTenantsPage from "./Compnent/Admin/Tenants/AddTenants/index";
import EditTenantsPage from "./Compnent/Admin/Tenants/EditTenants/index";
import PropertysPage from "./Compnent/Admin/Tenants/Propertys/index";
import EditProperty from "./Compnent/Admin/Tenants/Propertys/EditProperty";
import OwnerPage from "./Compnent/Admin/owner/index";
import AddOwnerPage from "./Compnent/Admin/owner/AddOwner";
import EditOwnerPage from "./Compnent/Admin/owner/EditOwner/index";
import OwnersPropertysPage from "./Compnent/Admin/owner/Propertys/index";
import EditOwnerProperty from "./Compnent/Admin/owner/Propertys/EditProperty";
import FeaturesMasterPage from "./Compnent/Admin/FeaturesMaster/index";
import DeveloperLogoPage from "./Compnent/Admin/DeveloperLogo/index";
import ProjectPage from "./Compnent/Admin/CreateProject/index";
import UnitModelPage from "./Compnent/Admin/UnitModel/index";
import AddUnitModelPage from "./Compnent/Admin/UnitModel/AddUnitModel/index";
import EditUnitModelPage from "./Compnent/Admin/UnitModel/EditUnitModel/index";
import SalesOfferPage from "./Compnent/Admin/SalesOffer/index";
import PublicFolderPage from "./Compnent/Admin/PublicFolder/index";
import PortalPage from "./Compnent/Admin/Portal/index";
import AgentPage from "./Compnent/Admin/Agent/index";
import AddAgentsPage from "./Compnent/Admin/Agent/AddAgent/index";
import EditAgentsPage from "./Compnent/Admin/Agent/EditAgent/index";
import AgentPropertysPage from "./Compnent/Admin/Agent/Property/index";
import EditAgentProperty from "./Compnent/Admin/Agent/Property/editProperty";
import TeamLeaderPage from "./Compnent/Admin/TeamLeader/index";
import LoginPage from "./Compnent/Admin/LoginPage/index";
import Page404 from "./Compnent/Admin/404Page";
import Error403 from "./Compnent/Admin/403Page";
import AgentPermission from "./Compnent/Admin/Agent/Permission/index";
import AddTeamLeaderPage from "./Compnent/Admin/TeamLeader/AddTeamLeader/index";
import EditTeamLeaderPage from "./Compnent/Admin/TeamLeader/EditTeamLeader/index";
import PermissionTeamLeader from "./Compnent/Admin/TeamLeader/Permission/index";
import RoleManagement from "./Compnent/Admin/Role/index";
import AddRolePage from "./Compnent/Admin/Role/AddRole/index";
import EditRolePage from "./Compnent/Admin/Role/EditRole/index";
import RolePermission from "./Compnent/Admin/Role/RolePermission";
import NotificationPage from "./Compnent/Admin/Notification";

function App() {
  const [isLogin, setIsLogin] = useState(true);
  return (
    <>
      {!isLogin ? (
        <>
          <Router>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="*" element={<Page404 />} />
              <Route path="/page-403" element={<Error403 />} />
            </Routes>
          </Router>
        </>
      ) : (
        <>
          <Router>
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/all-listing" element={<Alllisting />} />
              <Route path="/add-all-listing" element={<AddAllListingPage />} />

              <Route
                path="/edit-all-listing/:id"
                element={<EditAllListingPage />}
              />
              <Route path="/all-leads" element={<AllLeads />} />
              <Route path="/add-all-leads" element={<AddAllLeadPage />} />
              <Route path="/edit-all-leads/:id" element={<EditAllLeadPage />} />
              <Route path="/callleads" element={<CallLeads />} />
              <Route
                path="/propertyfinder-leads"
                element={<PropertyFinderLeads />}
              />
              <Route path="/bayut-leads" element={<BayutLeads />} />
              <Route path="/dubizzle-leads" element={<DubizzleLeads />} />
              <Route path="/website-leads" element={<WebsiteLeads />} />
              <Route path="/wa-chatbot-leads" element={<WAChatBotLeads />} />
              <Route path="/live-chat-leads" element={<LiveChatLeads />} />
              <Route path="/paid-leads1" element={<PaidLeads1 />} />
              <Route path="/paid-leads2" element={<PaidLeads2 />} />
              <Route path="/old-leads" element={<OldLeads />} />
              <Route
                path="/old-leads/old-lead-details/:id"
                element={<OldLeadDetails />}
              />
              <Route
                path="/old-leads/edit-old-lead/:id"
                element={<EditOldLead />}
              />
              <Route path="/leads-pool" element={<LeadsPool />} />
              <Route path="/leads-settings" element={<LeadsSettings />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/old-contact" element={<OldContacts />} />
              <Route
                path="/old-contact-details/:id"
                element={<OldContactDetails />}
              />
              <Route path="/calendar" element={<Calendars />} />
              <Route path="/calendar/:id" element={<CalendersDetails />} />
              <Route path="/all-deals" element={<AllDeals />} />
              <Route path="/add-all-deals" element={<AddAllDeals />} />
              <Route
                path="/all-deals-details/:id"
                element={<AllDealsDetails />}
              />
              <Route
                path="/all-deals-edit/:id"
                element={<EditAllDealsPage />}
              />
              <Route path="/old-deals" element={<OldDeals />} />
              <Route
                path="/old-deals-details/:id"
                element={<OldDealsDetailsPage />}
              />
              <Route path="/viewings" element={<Viewings />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/hrm" element={<HRM />} />
              <Route path="/location" element={<Location />} />
              <Route path="/sub-location" element={<SubLocation />} />
              <Route path="/projects" element={<Project />} />
              <Route path="/tenants" element={<TenantsPage />} />
              <Route path="/add-tenants" element={<AddTenantsPage />} />
              <Route path="/edit-tenants/:id" element={<EditTenantsPage />} />
              <Route
                path="/tenants-show-property/:id"
                element={<PropertysPage />}
              />
              <Route
                path="/tenants-edit-property/:id"
                element={<EditProperty />}
              />
              <Route path="/owners" element={<OwnerPage />} />
              <Route path="/add-owners" element={<AddOwnerPage />} />
              <Route path="/edit-owners/:id" element={<EditOwnerPage />} />
              <Route
                path="/owners-show-property/:id"
                element={<OwnersPropertysPage />}
              />
              <Route
                path="/owners-edit-property/:id"
                element={<EditOwnerProperty />}
              />
              <Route path="/features" element={<FeaturesMasterPage />} />
              <Route path="/developer-logo" element={<DeveloperLogoPage />} />
              <Route path="/create-project" element={<ProjectPage />} />
              <Route path="/unit-model/:id" element={<UnitModelPage />} />
              <Route path="/add-unit-model" element={<AddUnitModelPage />} />
              <Route
                path="/edit-unit-model/:id"
                element={<EditUnitModelPage />}
              />
              <Route path="/sales-offer" element={<SalesOfferPage />} />
              <Route path="/public-folder" element={<PublicFolderPage />} />
              <Route path="/portals" element={<PortalPage />} />
              <Route path="/agents" element={<AgentPage />} />
              <Route path="/add-agents" element={<AddAgentsPage />} />
              <Route path="/edit-agents/:id" element={<EditAgentsPage />} />
              <Route
                path="/agents-property/:id"
                element={<AgentPropertysPage />}
              />
              <Route
                path="/edit-agents-property/:id"
                element={<EditAgentProperty />}
              />
              <Route
                path="/agents-permission/:id"
                element={<AgentPermission />}
              />
              <Route path="/team-leaders" element={<TeamLeaderPage />} />
              <Route path="/add-team-leaders" element={<AddTeamLeaderPage />} />
              <Route
                path="/edit-team-leaders/:id"
                element={<EditTeamLeaderPage />}
              />
              <Route
                path="/permission-team-leaders/:id"
                element={<PermissionTeamLeader />}
              />
              <Route path="/role" element={<RoleManagement />} />
              <Route path="/add-role" element={<AddRolePage />} />
              <Route path="/edit-role/:id" element={<EditRolePage />} />
              <Route path="/role-permission/:id" element={<RolePermission />} />
              <Route path="/notification" element={<NotificationPage />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Router>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
